function Certifications() {
  return (
    <div className="certifications-section">
      <h2 className="sub-title">Certifications</h2>
      <ul className="section-list">
        {/* <li className="section-list-item">
          Japanese Language Proficiency Test N4. Issued on <mark> 2020 </mark>{" "}
          from The Japan Foundation.
        </li>
        <li className="section-list-item">
          Certificate of Participation for the Hult Prize competition. Issued on{" "}
          <mark> 2019</mark> from the Hult Prize Foundation.
        </li> */}
        <li className="section-list-item">
          AWS Technical Essentials (IAM, compute, storage, databases). Issued on{" "}
          <mark> 2024 </mark> from AWS training and certification.
        </li>
        <li className="section-list-item">
          Cisco Certified Network Associate Routing and Switching (CCNA). Issued
          on <mark> 2017 </mark> from Cisco.
        </li>
      </ul>
    </div>
  );
}

export default Certifications;
